<template>
    <auth-form-wrapper
      :title="loginTitle"
      :subtitle="loginSubtitle"
      :error="error"
      :community="communityData"
    >      
      <b-form class="auth-form mt-2" @submit.prevent="handleSubmit">
        <!-- Username/Email/ID -->
        <div class="mb-1">
          <label class="form-label">{{ usernameLabel }}</label>
          <b-form-input
            id="login-username"
            v-model="formData.username"
            type="text"
            name="username"
            :placeholder="usernameLabel"
            required
          />
        </div>
  
        <!-- Password -->
        <div class="mb-1">
          <label class="form-label">{{ $t('auth.login.form.password.label') }}</label>
          <div class="input-group input-group-merge">
            <b-form-input
              id="login-password"
              v-model="formData.password"
              :type="passwordFieldType"
              name="password"
              :placeholder="$t('auth.login.form.password.placeholder')"
              class="form-control-merge"
              required
            />
            <div class="input-group-append">
              <div class="input-group-text cursor-pointer" @click="togglePasswordVisibility">
                <feather-icon
                  :icon="passwordToggleIcon"
                  size="16"
                  class="toggle-password-icon"
                />
              </div>
            </div>
          </div>
        </div>
  
        <!-- Submit Button -->
        <div class="auth-btn-container d-flex justify-content-center">
          <b-button
            type="submit"
            variant="primary"
            class="auth-btn auth-btn-primary mt-2 mb-2"
            :disabled="invalid"
          >
            {{ $t('auth.login.form.action.sign-in') }}
          </b-button>
        </div>
      </b-form>

      <!-- SSO Buttons -->
      <template v-if="showSSOButtons">
        <div class="divider my-2">
          <div class="divider-text">{{ $t('auth.login.form.action.sign-in-with') }}</div>
        </div>
        <div class="auth-footer-btn d-flex justify-content-center">
          <b-button
            v-if="showMicrosoftButton"
            variant="outline-primary"
            :href="community && community.msalURL"
            class="mr-2"
          >
            <i class="fab fa-microsoft"></i>
          </b-button>
          <b-button
            v-if="showStaffButton && !isStaffLogin"
            variant="outline-primary"
            :href="staffLoginURL"
          >
            {{ $t('auth.login.staff-access') }}
          </b-button>
        </div>
      </template>

    </auth-form-wrapper>
  </template>
  
  <script>
  import { togglePasswordVisibility } from '@core/mixins/ui/forms'
  import { translateTranslationTable } from '@/@core/libs/i18n/utils'
  import AuthFormWrapper from '@/auth/components/AuthFormWrapper.vue'
  import Vue from 'vue'
  import useComponent from '@/store/component'
  import i18n from '@/plugins/i18n'
  import { getDomain } from '@/store/auth/auth.store'
  
  // Constants for special community keys
  const COMMUNITY_KEYS = {
    INHOSPITECNIA: 'f6d4dec0-e1ec-11ec-ac71-cd2eb883fdc3',
    BARCELONACTIVA: 'CXBT3al0hR',
    COEIC: '8f30c710-a169-11ed-88c5-c1eb2d036009'
  }
  
  export default {
    name: 'LoginSSO',
  
    components: {
      AuthFormWrapper
    },
  
    mixins: [togglePasswordVisibility],
  
    inject: ['authApiUrl'],
  
    data() {
      return {
        formData: {
          username: '',
          password: ''
        },
        error: null,
        invalid: false,
        community: null,
        isLoading: true,
      }
    },
  
    computed: {
      passwordToggleIcon() {
        return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
      },
  
      communitySlug() {
        return this.$route.params.communityId
      },

      communityKey() {
        return this.community && this.community.key
      },
  
      isInhospitecnia() {
        return this.community && this.community.key === COMMUNITY_KEYS.INHOSPITECNIA
      },
  
      usernameLabel() {
        if (this.community && this.community.key === COMMUNITY_KEYS.INHOSPITECNIA) {
          return this.$t('auth.login.form.email.label')
        }
        return this.$t('auth.login.form.username.label')
      },
  
      showSSOButtons() {
        return this.showMicrosoftButton || this.showStaffButton
      },
  
      showMicrosoftButton() {
        return this.community && this.community.key === COMMUNITY_KEYS.BARCELONACTIVA && this.community.msalURL
      },
  
      showStaffButton() {
        return this.community && this.community.key === COMMUNITY_KEYS.COEIC
      },
  
      isStaffLogin() {
        return this.$route.query.sso === 'false'
      },
  
      staffLoginURL() {
        return `${window.location.href}${window.location.href.includes('?') ? '&' : '?'}sso=false`
      },
  
      termsURL() {
        const baseURL = process.env.VUE_APP_COMMUNITY_URL.replace(
          '{subdomain}',
          this.community && this.community.subdomain || 'app'
        )
        return `${baseURL}/${this.communitySlug || 'my'}/legal-center/terms_conditions?locale=${this.$i18n.locale}`
      },
  
      policyURL() {
        const baseURL = process.env.VUE_APP_COMMUNITY_URL.replace(
          '{subdomain}',
          this.community && this.community.subdomain || 'app'
        )
        return `${baseURL}/${this.communitySlug || 'my'}/legal-center/privacy_policy?locale=${this.$i18n.locale}`
      },
  
      termsNectiosURL() {
        const baseURL = process.env.VUE_APP_COMMUNITY_URL.replace(
          '{subdomain}',
          this.community && this.community.subdomain || 'app'
        )
        return `${baseURL}/my/legal-center/terms_conditions?locale=${this.$i18n.locale}`
      },
  
      policyNectiosURL() {
        const baseURL = process.env.VUE_APP_COMMUNITY_URL.replace(
          '{subdomain}',
          this.community && this.community.subdomain || 'app'
        )
        return `${baseURL}/my/legal-center/privacy_policy?locale=${this.$i18n.locale}`
      },
  
      loginTitle() {
        if(this.isInhospitecnia){
          return this.$t('auth.login.sso.customers.inhospitecnia.title')
        }
        return this.$t('auth.login.sso.title')
      },
      loginSubtitle() {
        if(this.isInhospitecnia){
          return this.$t('auth.login.sso.customers.inhospitecnia.subtitle')
        }
        return this.$t('auth.login.sso.subtitle')
      },
      communityData() {
        return this.community;
      }
    },
  
    watch: {
      'formData.username'() {
        this.validateForm()
      },
      'formData.password'() {
        this.validateForm()
      },
      community(newVal) {
        console.log('Community changed:', newVal)
      },
      communitySlug: {
        immediate: true,
        handler() {
          this.loadCommunityData()
        }
      }
    },
  
    methods: {
      translate(field) {
        return translateTranslationTable(this.$i18n.locale, field)
      },
  
      validateForm() {
        this.invalid = !this.formData.username || !this.formData.password
      },
  
      async loadCommunityData() {
        try {
          const baseUrl = process.env.VUE_APP_AUTH_API_URL || 'https://auth-api.nectios.com'
          const apiUrl = `${baseUrl}/api/v1/login`
          
          const url = new URL(apiUrl)
          if (this.communitySlug) {
            url.searchParams.append('communitySlug', this.communitySlug)
          }
  
          console.log('Fetching community data from:', url.toString())
          const response = await fetch(url.toString())
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
          }
  
          const data = await response.json()
          console.log('Community data received:', data)
          this.community = data.data
          console.log('Community set to:', this.community)
  
          // Apply theme customizations if available
          if (this.community && this.community.customization && this.community.customization.theme) {
            this.applyThemeCustomizations(this.community.customization.theme)
          }
  
          this.isLoading = false
        } catch (error) {
          console.error('Error loading community data:', error)
          this.error = this.$t('auth.login.error')
        }
      },
  
      applyThemeCustomizations(theme) {
        if (theme.primaryColor) {
          document.documentElement.style.setProperty('--primary-color', theme.primaryColor)
          const hsl = theme.primaryColor.match(/\d+/g)
          document.documentElement.style.setProperty('--primary-color-h', hsl[0])
          document.documentElement.style.setProperty('--primary-color-s', hsl[1] + '%')
          document.documentElement.style.setProperty('--primary-color-l', hsl[2] + '%')
        }
        if (theme.secondaryColor) {
          document.documentElement.style.setProperty('--secondary-color', theme.secondaryColor)
          const hsl = theme.secondaryColor.match(/\d+/g)
          document.documentElement.style.setProperty('--secondary-color-h', hsl[0])
          document.documentElement.style.setProperty('--secondary-color-s', hsl[1] + '%')
          document.documentElement.style.setProperty('--secondary-color-l', hsl[2] + '%')
        }
      },
  
      async handleSubmit() {
        try {
          // Set a timeout for the request
          const timeoutPromise = new Promise((_, reject) => {
            setTimeout(() => reject(new Error('Request timeout')), 30000)
          })
          
          let endpoint = 'login'
          if (this.community && (this.community.key === COMMUNITY_KEYS.INHOSPITECNIA || 
              this.community.key === COMMUNITY_KEYS.COEIC)) {
            endpoint = 'sso'
          } else if (this.community && this.community.key === COMMUNITY_KEYS.BARCELONACTIVA) {
            endpoint = 'loginsoap'
          }
  
          const fetchPromise = fetch(
            `${this.authApiUrl}${endpoint}`,
            {
              method: 'POST',
              body: JSON.stringify({
                username: this.formData.username,
                password: this.formData.password,
                communitySlug: this.communitySlug,
                locale: this.$i18n.locale
              }),
              headers: {
                'Content-Type': 'application/json'
              }
            }
          )
          
          // Race between the fetch and the timeout
          const response = await Promise.race([fetchPromise, timeoutPromise])
  
          if (!response.ok) {
            throw new Error(this.$t('auth.login.error'))
          }
  
          const token = await response.text()
  
          // Set cookie for 1 year
          const date = new Date()
          date.setFullYear(date.getFullYear() + 1)
          
          // Import getDomain from auth.store.js instead of redefining it here
          // Get parent domain (e.g. ".nectios.com") for sharing across subdomains
          const parentDomain = getDomain() ? `.${getDomain()}` : (process.env.VUE_APP_DOMAIN_1 || 'localhost')
          console.log('Setting cookie with parent domain:', parentDomain);
  
          // Set cookie for the parent domain to share across subdomains
          Vue.$cookies.set('accessToken', token, {
            path: '/',
            domain: parentDomain,
            expires: date,
            secure: window.location.protocol === 'https:',
            sameSite: 'Lax'
          })
          
          // Also set in localStorage as backup
          localStorage.setItem('accessToken', token);
  
          // Redirect to community page
          const vueAppUrl = process.env.VUE_APP_APP_URL_1 || 'http://localhost:8080'
          const destinationPath = this.communitySlug ? `/${this.communitySlug}` : ''
          window.location.href = vueAppUrl + destinationPath
  
        } catch (error) {
          console.error('Login error:', error)
          this.error = this.community && this.community.key === COMMUNITY_KEYS.BARCELONACTIVA
            ? this.$t('auth.login.ba-error')
            : this.$t('auth.login.error')
        }
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>    
  .input-group-merge {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
  
    .form-control-merge {
      position: relative;
      flex: 1 1 auto;
      width: 1%;
      min-width: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  
    .input-group-append {
      display: flex;
      margin-left: -1px;
  
      .input-group-text {
        display: flex;
        align-items: center;
        padding: 0.571rem 1rem;
        margin-bottom: 0;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.45;
        color: #6e6b7b;
        text-align: center;
        white-space: nowrap;
        background-color: #f8f8f8;
        border: 1px solid #d8d6de;
        border-left: 0;
        border-top-right-radius: 0.357rem;
        border-bottom-right-radius: 0.357rem;
      }
    }
  
    .toggle-password-icon {
      cursor: pointer;
    }
  }
  
  .divider {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 1rem 0;
  
    &::before,
    &::after {
      content: '';
      flex: 1;
      border-bottom: 1px solid #d8d6de;
    }
  
    .divider-text {
      padding: 0 1rem;
      color: #6e6b7b;
    }
  }
  
  .auth-footer-btn {
    .btn {
      padding: 0.786rem 1.5rem;
      margin: 0 0.5rem;
      
      i {
        font-size: 1.2rem;
      }
    }
  }
  
  .privacy-terms {
    font-size: 0.875rem;
    color: #6e6b7b;
    
    a {
      color: var(--primary-color);
      text-decoration: none;
      
      &:hover {
        text-decoration: underline;
      }
    }
  }
  </style> 